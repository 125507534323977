<template>
  <div>
    <v-btn color="indigo" dark="" class="mr-5 mb-2" @click="get_Signature()">
      <v-icon class="mr-2"
              x-small>
        fas fa-file-signature
      </v-icon>
      Signatur anzeigen
    </v-btn>

    <v-dialog
        v-model="dialogSign"
        transition="dialog-top-transition"
        width="500">
      <v-card>
        <v-card-title class="text-h5">
          Unterschrift anzeigen
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <div id="signature">
            <v-progress-linear
                v-show="!comp_Signature"
                indeterminate
                color="green"
            ></v-progress-linear>
            <v-img
                v-if="comp_Signature"
                :src="comp_Signature">
            </v-img>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="close_dialog"
          >
            schliessen
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  name: 'shootingbookShowSignature',
  props: {
    item: {
      type: Object
    },
  },
  data: () => ({
    dialogSign: false,
  }),
  computed: {
    comp_Signature() {
      return this.$store.state.UserApp.Shootingbook_Entry_Signature.image;
    },
  },
  methods: {

    get_Signature() {
      const Data = {
        'shootingbookID': this.item.id
      }

      this.$store.dispatch('UserApp/get_Shootingbook_sign', Data)
          .then(
              this.dialogSign = true
          )
    },
    close_dialog(){
      this.dialogSign = false;
      this.$store.commit('UserApp/CLEAR_SHOOTINGBOOK_SIGNATURE');
    }

  },
}
</script>
<style scoped>
#signature {
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100%;
  height: 200px;
}

.v-progress-circular {;
  display: block;
  margin: 1rem auto;
  width: 300px;
}


</style>