<template>
  <div>
    <v-btn color="indigo" dark="" class="mr-5 mb-2" @click="open_sign_dialog()">
      <v-icon class="mr-2"
              x-small>
        fas fa-signature
      </v-icon>
      Signieren
    </v-btn>

    <v-dialog
        v-model="dialogSign"
        transition="dialog-top-transition"
        width="500">
      <v-card>
        <v-card-title class="text-h5">
          Eintrag signieren
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-subheader>Verein</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="editedItem.comp_vereinname"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-subheader>Bahn</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="editedItem.bahn"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-subheader>Waffe</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="editedItem.LangBez"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-subheader>Datum</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="editedItem.BeginFormat"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-subheader>Funktion</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-model="editedItem.aktivitaet"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row><span class="mb-1 ml-7">Unterschrift</span></v-row>
            <v-row>
              <div class="wrap">
                <ejs-signature
                    :created=created
                    id="signature">
                </ejs-signature>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card-actions">
          <v-btn class="success justify-right mt-1" text @click="clear_signature()">UNDO</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="close_sign_dialog()"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="sign_entry()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {SignatureComponent} from '@syncfusion/ej2-vue-inputs';
import {SignaturePlugin} from "@syncfusion/ej2-vue-inputs";
import {getComponent} from '@syncfusion/ej2-base';
import {enableRipple} from '@syncfusion/ej2-base';
import Vue from "vue";

enableRipple(true);
Vue.use(SignaturePlugin);
export default {
  name: 'shootingbookSignEntry',
  components: {
    'ejs-signature': SignatureComponent
  },
  props: {
    item: {
      type: Object
    },
  },
  data: () => ({
    dialogSign: false,
    editedItem: {}
  }),
  computed: {
    comp_item() {
      return this.item
    }

  },
  methods: {
    dialog_open() {
      this.editedItem = Object.assign({}, this.comp_item);
      this.dialogSign = true;
    },
    async sign_entry() {
      var obj_signature = getComponent(document.getElementById('signature'), 'signature');
      var data = obj_signature.getSignature('Jpeg');

      if (!data) {
        return
      }

      let Data = {
        'shootingbookID': this.editedItem.id,
        'signature': data
      }

      await this.$store.dispatch('UserApp/sign_Shootingbook_entry', Data)
          .then(
              this.dialogSign = false
          )
    },
    created() {
      setTimeout(() => {
        var signatureObj = getComponent(document.getElementById('signature'), 'signature');
        signatureObj.refresh();
      }, 100);
    },
    open_sign_dialog() {
      this.editedItem = Object.assign({}, this.comp_item);
      this.dialogSign = true;
      // this.clear_signature();
    },
    close_sign_dialog() {
      this.dialogSign = false;
    },
    undo() {
      var signature = getComponent(document.getElementById('signature'), 'signature');
      if (!signature.disabled && !signature.isReadOnly) {
        signature.undo();
      }
    },
    clear_signature() {
      var signature = getComponent(document.getElementById('signature'), 'signature');
      if (!signature.disabled && !signature.isReadOnly) {
        signature.clear();
      }
    }

  },
}
</script>
<style scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';

#signature {
  border: 1px solid lightgray;
  height: 100%;
  width: 100%;
}

.wrap {
  margin-left: 15px;
  margin-right: 15px;
  height: 200px;
  width: 650px;
}

.v-progress-circular {;
  display: block;
  margin: 1rem auto;
  width: 300px;
}
</style>